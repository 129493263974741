import { AppContext } from "../../context/AppContext"
import { useContext} from "react"

export default function ProductsTable({ displayProducts }) {

    const { capitalizeWords, globalUser } = useContext( AppContext )

    return(

        <table className="table-main">
            <thead>

                <tr className="products-table-header">
                    <th>Artículo</th>
                    <th>Categoría</th> 
                    <th>Precio</th>
                    <th>Info</th>
                </tr>
            </thead>
            <tbody>
                {displayProducts.map(( product, idx ) => {
                    return(

                        <tr className={ idx % 2 === 0 ? 'dark' : ''} key={idx}>
                            <td>{capitalizeWords(product.descripcion)}</td>
                            <td className="centered-text">{`${capitalizeWords(product.rubro)} / ${capitalizeWords(product.subrubro)}`}</td>
                            {globalUser ?
                                <td className="centered-text price">
                                    {globalUser.enabled ?
                                        `$${product[`lista${globalUser.list}`]}`
                                    :
                                        'Usuario no habilitado'
                                    }
                                </td>
                                :
                                <td className="centered-text price">Iniciar Sesión</td>
                            }
                            <td className="info-td">
                                <div className="product-detail-div">
                                    {capitalizeWords(product.info)}
                                </div>
                                <span className="table-info-btn">+</span>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}