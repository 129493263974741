import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import ProductsContainer from "../Components/ProductsContainer/ProductsContainer";
import SideBar from "../Components/SideBar/SiderBar";
import WhatsappBtn from "../Components/WhatsappBtn/WhatsappBtn";

export default function Products() {

    return(
        
        <div className="view-container">
            <Header showButtons={true} />
            <div className="flex-row">
                <SideBar/>
                <ProductsContainer />
            </div>
            <WhatsappBtn phone={'+54 9 2616 06-6990'} message={'Hola, me contacto para solicitarles información sobre sus productos.'}/>
            <Footer/>
        </div>
    )
}