import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppProvider } from './context/AppContext';

//VIEWS
import Home from "./views/Home";
import Login from "./views/Login"
import Products from './views/Products'
import Admin from './views/Admin'
import Offers from './views/Offers'

function App() {
  return (
    <>
    <BrowserRouter>
      <AppProvider>
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/login' element={<Login/>} />
          <Route exact path='/products' element={<Products/>} />
          <Route exact path='/offers' element={<Offers/>} />
          <Route exact path='/admin' element={<Admin/>} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
    </>
  );
}

export default App;
