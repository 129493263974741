import CoverUploader from '../CoverUploader/CoverUploader'

export default function CoversContainer () {
    return(
        <div className="uploader-container">
            <h4>Actualizar imagen de portada</h4>
            <div className="input-container shadow-main">
                <p>Seleccionar archivo de imagen:</p>
                <CoverUploader/>
            </div>
        </div>
    )
}