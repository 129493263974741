import { useEffect, useState } from "react"

import { getStorage, ref, getDownloadURL } from "firebase/storage";


function useCover() {

    //STATE
    const [ coverURL, setCoverURL ] = useState(null)

    //FUNCTIONS
    const getImageURL =  () => {

        // Create a reference with an initial file path and name
        const storage = getStorage()
        const pathReference = ref(storage, 'covers/cover')


        getDownloadURL(pathReference)
            .then(( url ) => {
                //Stores url in state
                setCoverURL(url)
            })
            .catch((err)=> console.log(err))
    }

    //EFFECTS
    useEffect(() => {

        getImageURL()
        
    },[])




    return coverURL
}

export default useCover