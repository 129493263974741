import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

export default function WhatsappBtn({phone, message}) {

    //VARIABLES
    const URL= 'https://wa.me/'

    const formattedPhone = phone.replaceAll(/\s/g, "").replaceAll( /[^0-9]/g, "")
    const formattedMessage = message.split(' ')
        .join('+')

    const url = `${URL+formattedPhone}?text=${formattedMessage}`

    return(
        <div className="whatsapp-btn">
            <a 
            target='_blank'
            rel='noopener noreferrer' 
            href={url}
            >
                <FontAwesomeIcon icon={faWhatsapp}/>
            </a>
        </div>
    )
    
}