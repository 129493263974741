import { AppContext } from "../../context/AppContext"
import { useContext } from "react"
import { getAuth, signOut } from "firebase/auth"
import { Link } from "react-router-dom"
import app from "../../fb"

export default function Header({ showButtons }) {

    const { globalUser } = useContext(AppContext)

    //Initalize Firebase
    const auth = getAuth(app)

    return(
        <div className="header-main-container flex-row">
            <div className="logo-container">
                <img src="/logo.png" alt="mendocomp logo"/>
            </div>
            {
                showButtons &&
                <div className="login-btn-container">
                    {
                        ! globalUser ?
                        <Link to='/login'>
                            <button className="btn light" >Ingresar / Registrarse </button>
                        </Link>
                        :
                        <Link to='/'>
                            <button className="btn light" onClick={()=>{signOut(auth)}}>Cerrar Sesión</button>
                        </Link>
                    }
                </div>
            }
        </div>
    )
}