import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'

export default function SideBar() {

    //CONTEXT
    const { globalUser } = useContext(AppContext)

    return(
        <div className="sidebar-main-container">
            <ul>
                <NavLink to={'/'}>
                    <li>INICIO</li>
                </NavLink>
                <NavLink to={'/products'}>
                    <li>PRODUCTOS</li>
                </NavLink>
                <NavLink to={'/offers'}>
                    <li>OFERTAS</li>
                </NavLink>
                {/* <NavLink to={'/admin'}>
                    <li>ADMIN</li>
                </NavLink> */}
                {globalUser && globalUser.isAdmin &&

                //This option is only shown to active admin users:
                <NavLink to={'/admin'}>
                    <li>ADMIN</li>
                </NavLink>
                }
            </ul>
        </div>
    )
}