import PriceListUploader from "../PriceListUploader/PriceListUploader";

export default function PriceListsContainer() {
    return(
        <div className="uploader-container">
            <h4>Actualizar lista de precios</h4>
            <div className="input-container shadow-main">
                <p>Seleccionar archivo .csv:</p>
                <PriceListUploader/>
            </div>
        </div>
    )
}