import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/SideBar/SiderBar";
import AdminPanel from "../Components/AdminPanel/AdminPanel";

export default function Admin() {

    return(
        <div className="view-container">
            <Header showButtons={true} />
            <div className="flex-row">
                <SideBar/>
                <AdminPanel/>
            </div>
            <Footer/>
        </div>
    )
}