import { useState } from "react"

import { getFirestore, doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import Swal from 'sweetalert2'

import app from "../../fb";
import Loader from "../Loader/Loader";

export default function CoverUploader() {

    //STATES
    const [ imgFile, setImgFile ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)

    //DB
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    //Create a root reference
    const storage = getStorage()

    //FUNCTIONS
    const handleFile = (e) => {
        //Stores img file in state
        setImgFile(e.target.files[0])
    }

    const handleUpload = () => {
        //Requires confirmation and then calls function to update cover from DB
        Swal
        .fire({
            text: `Desea actualizar la portada?`,
            icon: 'warning',
            iconColor: '#FFC106',
            confirmButtonText: 'Actualizar',
            showDenyButton: true,
            denyButtonText:'Cancelar',
            buttonsStyling: false,
            customClass:{
                confirmButton: 'btn btn-primary alert-btn order-2',
                denyButton: 'btn btn-light alert-btn order-1',
                popup: 'alert-container'
            }
        })
        .then( (res) => {
            if(res.isConfirmed){
                //Calls function to edit information in db
                updateCoverInDb()
            }
        })

    }

    const updateCoverInDb = async () => {
    
        //Turns loader on
        setIsLoading(true)

        //Deletes existing cover from db from db
        await deleteDoc(doc(db, "covers", 'cover'))

        //Create a reference to new cover
        const coverRef = ref(storage, 'covers/cover')

        //Calls upload function
        await uploadBytes(coverRef, imgFile)

        //Turns loader off and clears component state
        setIsLoading(false)
        setImgFile(null)

        //Shows promt with confirmation
        Swal.fire({
            text: `Imagen actualizada.`,
            icon: 'success',                    
            timer: 1500,
            buttonsStyling: false,
            showConfirmButton: false,
        })

    }


    return(
        <>  
            {isLoading?

                <Loader/>
                :
                <>
                    {!imgFile ?
                    <div className='file-input-container'>
                        <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e)=> handleFile(e)} className=''/>
                    </div>

                    :

                    <button onClick={handleUpload} className='btn light'>
                        Publicar nueva portada
                    </button>
                    
                    }
                </>
            }  
        </>
    )

}