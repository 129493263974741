import { useContext, useState } from "react"
import { AppContext } from "../../context/AppContext"
import Loader from "../Loader/Loader";

import { getFirestore, doc, updateDoc, deleteDoc} from "firebase/firestore";
import Swal from 'sweetalert2'

import app from "../../fb";



export default function ClientsTable({ clients, getClients }) {

    //CONTEXT
    const { capitalizeWords } = useContext(AppContext)

    //STATES
    const [ isLoading, setIsLoading ] = useState(false)
    const [ spottedUser, setSpottedUser ] = useState(null)

    //DB
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    //FUNCTIONS
    const handleCheckbox = (user) => {

        Swal
        .fire({
            text: `Desea ${user.enabled ? 'desactivar': 'activar'} este usuario?`,
            icon: 'warning',
            iconColor: '#FFC106',
            confirmButtonText: 'Confirmar',
            showDenyButton: true,
            denyButtonText:'Cancelar',
            buttonsStyling: false,
            customClass:{
                confirmButton: 'btn btn-primary alert-btn order-2',
                denyButton: 'btn btn-light alert-btn order-1',
                popup: 'alert-container'
            }
        })
        .then((res) => {
            if(res.isConfirmed){
                //Calls function to edit information in db
                updateUserEnabled(user)
            }
        })

    }

    const handleSelect = (e, email) => {
        //Calls function to update list information in db
        const newList = e.target.value
        updateUserListType(newList, email)

    }

    const handleDelete = (email) => {
        //Requires confirmation and then calls function to delete user from DB
        Swal
        .fire({
            text: `Desea eliminar este usuario?`,
            icon: 'warning',
            iconColor: '#FFC106',
            confirmButtonText: 'Eliminar',
            showDenyButton: true,
            denyButtonText:'Cancelar',
            buttonsStyling: false,
            customClass:{
                confirmButton: 'btn btn-primary alert-btn order-2',
                denyButton: 'btn btn-light alert-btn order-1',
                popup: 'alert-container'
            }
        })
        .then( (res) => {
            if(res.isConfirmed){
                //Calls function to edit information in db
                deleteUserFromDb(email)
            }
        })
    }

    const showClientInfo = (user) => {
        //Shows client details
        setSpottedUser(user)

    }

    const deleteUserFromDb = async (email) => {

        //Turns loader on
        setIsLoading(true)

        //Deletes user from db
        await deleteDoc(doc(db, "users", email));

        //Turns loader off user has been deleted 
        setIsLoading(false)

        //After deleting the user, updates clients list:
        getClients()

        //Shows promt with confirmation
        Swal.fire({
            text: `Usuario eliminado.`,
            icon: 'success',                    
            timer: 1500,
            buttonsStyling: false,
            showConfirmButton: false,
        })

    }

    const updateUserListType = async (newList, email) => {
        //Turns loader on
        setIsLoading(true)
        
        //Creates document reference using user email and then updates the referenced document
        const userRef = doc(db, "users", email);
        await updateDoc(userRef, { list: newList })

        //Turns loader off after doc has been updated
        setIsLoading(false)

        //Gets updated client information from hook
        getClients()

        //Shows promt with confirmation
        Swal.fire({
            text: `Lista actualizada.`,
            icon: 'success',                    
            timer: 1500,
            buttonsStyling: false,
            showConfirmButton: false,
        })

    }
    const updateUserEnabled = async (user) => {
        
        const userRef = doc(db, "users", user.email);
        const enabled = !user.enabled

        //Updates doc
        await updateDoc(userRef, { enabled })

        //Shows alert
        Swal.fire({
            text: `Usuario ${user.enabled ? 'desactivado': 'activado'}.`,
            icon: 'success',                    
            timer: 1500,
            buttonsStyling: false,
            showConfirmButton: false,
        })

        //Makes new call to db to update information of client list and products with updated permissions
        getClients()
        

    }

    return(
        <>
        {
            isLoading?
            <Loader/>
            :
            <>
            {
                !spottedUser ?

                <table className="table-main">
                    <thead>
        
                        <tr className="products-table-header">
                            <th>Nombre</th>
                            <th>e-mail</th> 
                            <th>Teléfono</th> 
                            <th>Lista de Precios</th>
                            <th>Activo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map(( client, idx ) => {
                            return(
        
                                <tr className={ idx % 2 === 0 ? 'dark' : ''} key={idx}>
                                    <td>{capitalizeWords(client.fantasyName)}</td>
                                    <td className="centered-text">{client.email.toLowerCase()}</td>
                                    <td className="centered-text">{client.phone}</td>
                                    <td className="centered-text">
                                        <select value={client.list} onChange={ (e) => handleSelect(e, client.email)}>
                                            <option value={'1'}>{`Lista 1`}</option>
                                            <option value={'2'}>{`Lista 2`}</option>
                                            <option value={'3'}>{`Lista 3`}</option>
                                        </select>
                                    </td>
                                    <td className="info-td centered-text">
                                        <input type="checkbox" checked={client.enabled}  onChange={()=>handleCheckbox(client)}/>
                                    </td>
                                    <td className="options-td">
                                        <span className="table-info-btn info" onClick={()=>showClientInfo(client)}>i</span>
                                        <span className="table-info-btn delete" onClick={()=>handleDelete(client.email)}>
                                            <p>x</p>
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                :
                <div className="user-detail-main section-main">
                    <button onClick={()=>setSpottedUser(null)}>
                        <p>X</p>
                    </button>
                    <div className="client-detail-body">
                        <h3>Detalle de Cliente</h3>
                        <h4>Información General</h4>
                        <p><b>Nombre Fantasía: </b>{capitalizeWords(spottedUser.fantasyName)}</p>
                        <p><b>Razón Social: </b>{capitalizeWords(spottedUser.legalName)}</p>
                        <h4>Información de Contacto</h4>
                        <p><b>Persona de contacto: </b>{capitalizeWords(spottedUser.contact)}</p>
                        <p><b>Teléfono: </b>{spottedUser.phone}</p>
                        <p><b>Correo electrónico: </b>{spottedUser.email.toLowerCase()}</p>
                        <p><b>Domiclio: </b>{`${capitalizeWords(spottedUser.address.trim())}, ${capitalizeWords(spottedUser.streetNumber.trim())}, ${capitalizeWords(spottedUser.county.trim())}`}</p>
                        <h4>Información tributaria</h4>
                        <p><b>CUIT: </b>{spottedUser.cuit}</p>
                        <p><b>Condición AFIP: </b>{capitalizeWords(spottedUser.tributaryInfo)}</p>
                        <p><b>Lista de precio: </b>{`Lista ${spottedUser.list}`}</p>
                        <h4>Permisos</h4>
                        <p><b>Administrador: </b>{spottedUser.isAdmin? 'Si': 'No'}</p>
                        
                    </div>
                </div>
            }
            </>
        }
        </>
    )
}