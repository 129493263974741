import { useEffect, useState, useContext, useCallback } from "react";
import useProducts from "../../hooks/useProducts";
import { AppContext } from "../../context/AppContext";
import ProductsTable from "../ProductsTable/ProductsTable";
import Loader from "../Loader/Loader";

export default function ProductsContainer () {

    //CONTEXT
    const {capitalizeWords} = useContext(AppContext)

    //STATES
    const [ filteredProducts, setFilteredProducts ] = useState([])
    const [ displayProducts, setDisplayProducts ] = useState([])
    const [ category, setCategory ] = useState('all')
    const [ categories, setCategories ] = useState([])
    const [ inputValue, setInputValue ] = useState('')

    //HOOKS
    const {products} = useProducts()

    //FUNCTIONS
    const handleSelect = (e) => {
        setCategory(e.target.value)
        setInputValue('')

    }

    const handleInput = (e) => {
        setInputValue(e.target.value)
    }

    const filterProductsByCategory = useCallback(() => {
        const filteredArray = products.filter(product => {
            const selectedCategory = category.toLowerCase()
            const productCategory = product.rubro.toLowerCase()
            return productCategory === selectedCategory
        })

        return filteredArray

    },[category, products])

    const findProductByKeyword = useCallback(() => {

        //This will be the string to compare:
        const searchString = inputValue.toLowerCase()

        const filteredArray = products.filter(obj => {
            return(

                Object.values(obj).some(value => {
                    const lowerCaseValue =  value.toLowerCase()
                    return lowerCaseValue.includes(searchString)
                })
            )

        })
        if(filteredArray.length > 0){
            //If there are matching products, sets results to filteredProducts. If not, sets filteredProducts to 0
            setFilteredProducts(filteredArray)
        } else {
            setFilteredProducts([])

        }
        // console.log(filteredArray)


    },[inputValue, products])

    const findUniqueCategories = useCallback(() => {

        //Gets all categories from objects in products array:
        const categoriesArray = []
        products.forEach( product => {
            // console.log(product)
            if(product.rubro && !categoriesArray.includes(product.rubro)){
                // console.log('llega?')
                //If the property is present in current product and its value is not in categoriesArray yet, it pushes into it
                categoriesArray.push(product.rubro)
            }
        })
        return categoriesArray
    },[products])

    //EFFECTS
    useEffect(() => {

        if(!filteredProducts.length > 0) {
            if(inputValue !== ''){
                // console.log('dos')
                setDisplayProducts([])
                setCategory('void')
            } else {
                // console.log('uno')
                //If filtered products array is not larger than 0, all products are shown:
                setDisplayProducts(products)
                //Select is set to : all
                setCategory('all')

            }
        } else {
            //Else, filtered array is shown and category is set to void (disabled) option
            setDisplayProducts(filteredProducts)
            setCategory('void')
        }

    },[products, filteredProducts, inputValue]) 

    useEffect(() => {
        //When products are loaded, gets all categories to populate select.
        if(products.length > 0) {
            // console.log('llega')
            const uniqueCategories = findUniqueCategories()
            // console.log(uniqueCategories)
            //Stores result in state
            setCategories(uniqueCategories)
            
        } 
        
    },[products, findUniqueCategories]) 
    
    useEffect(() => {

        if(inputValue !== '') {

            //This function searches for products with coincidences with inputValue:
            findProductByKeyword()
            //Clears select
            setCategory('void')

        }else{
            //If input is empty string, shows all categories in select
            setCategory('all')
        }

    },[inputValue, findProductByKeyword]) 

    useEffect(() => {
        //If category is all, shows all products. If not, searches for matching products
        if(category === 'all'){
            setDisplayProducts(products)
        }
        else{
            const filteredProducts = filterProductsByCategory()
            setDisplayProducts(filteredProducts)
        }

    },[category, products, filterProductsByCategory])
    
    return(
        <>
        {
            products.length > 0 ?

            <div className="products-main-container section-main">
                <div className="products-option-bar">
                    <h4>Precios IVA incluido</h4>
                    <div>

                        <select value={category} onChange={(e) => handleSelect(e)} className="large-width products-bar-item ">
                            <option value="void" disabled></option>
                            <option value="all">Todos los productos</option>
                            {
                                categories.map(((category, idx) => {
                                    return(
                                        <option value={category.toLowerCase()} key={idx}>{capitalizeWords(category)}</option>
                                    )
                                }))
                            }
                        </select>
                        <input type="text" className={'products-bar-item '} placeholder={'Buscar...'} value={inputValue} onChange={(e) => handleInput(e)}/>
                    </div>
                </div>
                {inputValue !== '' && displayProducts.length === 0 ?
                <div className="no-results-message">
                    <p>No hay coincidencias con su búsqueda.</p>
                </div>
                :
                <ProductsTable displayProducts={displayProducts}/>
                }
            </div>
            :
            <Loader/>
        }
        </>
    )
}