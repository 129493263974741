import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import LoginForm from "../Components/LoginForm/LoginForm";
import SideBar from "../Components/SideBar/SiderBar";
import WhatsappBtn from "../Components/WhatsappBtn/WhatsappBtn";

export default function Home() {
    return(
        <div className="view-container">
            <Header showButtons={false} />
            <div className="flex-row">
                <SideBar/>
                <LoginForm/>
            </div>
            <WhatsappBtn phone={'+54 9 2616 06-6990'} message={'Hola, me contacto para solicitarles información sobre sus productos.'}/>
            <Footer/>
        </div>
    )
}