import { useState } from "react"

import PriceListsContainer from "../PriceListsContainer/PriceListsContainer"
import ClientsContainer from "../ClientsContainer/ClientsContainer"
import CoversContainer from "../CoversContainer/CoversContainer"


export default function AdminPanel() {

    //STATES
    const [ activeSection, setActiveSection ] = useState('clients')
    
    return(
        <div className="section-main admin-main-container">
            <div className="admin-option-bar">
                <ul>
                    <li>
                        <button onClick={()=>setActiveSection('clients')} className={activeSection === 'clients' ? 'active' : ''}>Clientes</button>
                    </li>
                    <li>
                        <button onClick={()=>setActiveSection('lists')} className={activeSection === 'lists' ? 'active' : ''}>Listas de Precios</button>
                    </li>
                    <li>
                        <button onClick={()=>setActiveSection('covers')} className={activeSection === 'covers' ? 'active' : ''}>Portadas</button>
                    </li>
                </ul>
            </div>
            <div className="admin-inner-container">
                {activeSection === 'clients' &&
                    <ClientsContainer/>
                }
                {activeSection === 'lists' &&
                    <PriceListsContainer/>
                }
                {activeSection === 'covers' &&
                    <CoversContainer/>
                }
            </div>
        </div>
    )
}