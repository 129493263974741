import { useCallback, useEffect, useState } from "react";
import { doc, getDoc, getFirestore} from "firebase/firestore"; 

import app from "../fb";

function useProducts() {

    //STATES
    const [ products, setProducts ] = useState([])

    //FIREBASE
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    
    //FUNCTIONS
    const sortArray = (array) => {
        return array.sort((a, b) => {

            const propA = a.descripcion.toLowerCase()
            const propB = b.descripcion.toLowerCase()

            if (propA < propB) {
                return -1
            }
            if (propA > propB) {
                return 1
            }
            return 0

        })

    }

    
    const getProducts = useCallback(async () => {
        
        const filterData = (arr) => {
    
            //Filters data with empty fields
            const filteredArray = arr.filter(item => item['codigo'] !== '')
            //Calls function that sorts array
            const sortedArray = sortArray(filteredArray)

            return sortedArray
        
        }

        const docRef = doc(db, "priceLists", "currentList");
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
            //Filters data with empty fields and sort alphabetically
            const filteredArray = filterData(docSnap.data().data)

            //Stores filtered Array in state
            setProducts(filteredArray)

        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
        
    },[db])

    useEffect(() => {
        getProducts()
    },[getProducts])

    return {
        products,
    }
}

export default useProducts