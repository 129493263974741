import { useState, useContext } from "react"
import Loader from "../Loader/Loader"
import Swal from 'sweetalert2'
import app from "../../fb"
import { AppContext } from "../../context/AppContext"
import { 
    getAuth, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword } from 'firebase/auth'
import { 
    getFirestore, 
    setDoc, 
    doc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"


//Initalize Firebase
const auth = getAuth(app)

//Initialize Cloud Firestore and get reference to the service
const db = getFirestore(app)



export default function LoginForm () {
    
    //STATES
    const [isLogging, setIsLogging] = useState(true)
    const [tributaryInfo, setTributaryInfo] = useState('')

    //CONTEXT
    const { isLoading, setIsLoading } = useContext(AppContext)

    //ROUTER
    const navigate = useNavigate()
    
    //FUNCTIONS
    const handleSelect = (e) => {
        setTributaryInfo(e.target.value)
    }
    const createUser = (user, password) => {

        //Calls Firebase function passing auth object and user data
        createUserWithEmailAndPassword(auth, user.email, password)
            .then(()=>{

                //Calls function to create user in Firestore db 
                // console.log('creating user: ' +user)
                createUserInDb(user)
            })
            .catch(err=>{

                setIsLoading(false)
                //Sweet alert for errors
                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass:{
                        confirmButton: 'btn btn-primary alert-btn',
                        popup: 'alert-container'
                    }
                })
            })

    }

    const userLogin = (email, password) => {

        //Calls Firebase function to sign in
        signInWithEmailAndPassword(auth,email, password)
            .then(()=>{
                setIsLoading(false)
                Swal.fire({
                    
                    text: 'Bienvenido',
                    icon: 'success',                    
                    timer: 1500,
                    buttonsStyling: false,
                    showConfirmButton: false,
                })
                navigate('/')
            })
            .catch(err=>{

                //If error is catched, sets loader to false and shows error in alert
                setIsLoading(false)
                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass:{
                        confirmButton: 'btn btn-primary alert-btn',
                        popup: 'alert-container'
                    }
                })
            })
    }

    const submitHandler = (e) => {
        
        //Prevents browser from refreshing
        e.preventDefault()

        //Turns Loader on
        setIsLoading(true)

        //Gets information from form
        const email = e.target.email.value
        const password = e.target.password.value

        //Calls proper function depending on state
        if(!isLogging){

            //Gets values from form to create user object
            const legalName = e.target.legalName.value
            const fantasyName = e.target.fantasyName.value
            const contact = e.target.contact.value
            const address = e.target.address.value
            const streetNumber = e.target.streetNumber.value
            const county = e.target.county.value
            const phone = e.target.phone.value
            const cuit = e.target.cuit.value


            const user = {
                email, 
                legalName,
                fantasyName,
                contact,
                address,
                streetNumber,
                county,
                phone,
                cuit,
                tributaryInfo,
                enabled: false,
                isAdmin: false,
                list: null
            }
            if(email !== '' &&
                legalName !== '' &&
                fantasyName !== '' &&
                contact !== '' &&
                address !== '' &&
                streetNumber !== '' &&
                county !== '' &&
                phone !== '' &&
                cuit!== '' &&
                tributaryInfo !== ''){

                    createUser(user, password)
                }
            else{
                //If is there any missing field, stops loader and shows message:
                setIsLoading(false)
                Swal.fire({
                    title: 'Oops!',
                    text: 'Por favor complete todos los campos del formulario.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass:{
                        confirmButton: 'btn btn-primary alert-btn',
                        popup: 'alert-container'
                    }
                })
            }

        }else{
            userLogin(email, password)
        }

    }

    const createUserInDb = (user) =>{

        //Creates doc for user with email as identifier
        setDoc(doc(db, 'users', user.email), user)
            .then(()=>{
                setIsLoading(false)
                Swal.fire({
                    title: 'Usuario creado',
                    text: 'Bienvenido',
                    icon: 'success',                    
                    timer: 1500,
                    buttonsStyling: false,
                    showConfirmButton: false,
                })
                navigate('/')
            })
            //If error is thrown
            .catch(err=>{
                setIsLoading(false)
                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass:{
                        confirmButton: 'btn btn-primary alert-btn',
                        popup: 'alert-container'
                    }
                })
            })
    }


    return(
        <div className="section-main">
            {isLoading && <Loader/>}

            <div className="login-form-container flex-column">
                <>
                <h3>{isLogging ? 'Bienvenido' : 'Registrar usuario'}</h3>
                <form className="login-form flex-column rounded-container shadow-main" onSubmit={submitHandler}>
                    {
                    isLogging ?
                    //This is rendered if a new user is being created:
                    <>
                        <label className="flex-row">Correo electrónico:  
                            <input type='text' name="email" autoComplete="on"/>
                        </label>
                        <label className="flex-row">Contraseña:
                            <input type='password' name="password" autoComplete="current-password"/>
                        </label>
                    </>
                    :
                    <>
                        <label className="flex-row">Razón Social: 
                            <input type='text' name="legalName"/>
                        </label>
                        <label className="flex-row">Nombre Fantasía:
                            <input type='text'  name="fantasyName"/>
                        </label>
                        <label className="flex-row">Persona de Contacto:
                            <input type='text'  name="contact"/>
                        </label>
                        <label className="flex-row">Domicilio:
                            <input type='text'  name="address" placeholder="Calle" className="large-width"/>
                            <input type='text'  name="streetNumber" placeholder="Numeración" className="min-width"/>
                        </label>
                        <label className="flex-row">Localidad:
                            <input type='text'  name="county"/>
                        </label>
                        <label className="flex-row">Teléfono:
                            <input type='text'  name="phone"/>
                        </label>
                        <label className="flex-row">CUIT:
                            <input type='text'  name="cuit"/>
                        </label>
                        <label className="flex-row">Condición ante AFIP: 
                            <select value={tributaryInfo} onChange={(e) => handleSelect(e)} className="large-width">
                                <option value="" disabled></option>
                                <option value="Monotributista">Monotributista</option>
                                <option value="IVA RI">IVA RI</option>
                                <option value="Excento">Excento</option>
                            </select>
                        </label>
                        <div className="login-inner-container rounded-btn"> 
                            <h4>Datos de login</h4>
                            <p>Por favor indique un correo válido, el mismo será utilizado para notificarlo una vez haya sido activado su usuario</p>  

                            <label className="flex-row">Correo electrónico:  
                                <input type='text' name="email" autoComplete="off"/>
                            </label>
                            <label className="flex-row">Contraseña:
                                <input type='password' name="password"/>
                            </label>                        
                        </div>
                    </>
                    }
                    <button className="btn dark login-btn">{isLogging ? 'Ingresar' : 'Registrarse'}</button>
                </form>
                <div className=" flex-row centered toggle-login-div">
                    <p>{isLogging ? '¿Necesita crear un usuario?  ' : '¿Ya tiene cuenta?  '}</p>
                    <button className="btn light" onClick={()=>{setIsLogging(!isLogging)}}>{isLogging ? 'Registrarse' : 'Ingresar'}</button>
                </div>

                </>  
            </div>
        </div>
    )
}