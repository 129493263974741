import useCover from "../../hooks/useCover"
import Loader from "../Loader/Loader"

export default function Hero() {

    const cover = useCover()

    return(

        <>
            {!cover ?
            <Loader/>
            :
            <div className="hero-main-container section-main">
                <img src={cover} alt="cover" loading="lazy"/>
                <div className="hero-contact-container">
                    <div className="phone-container">
                        <p>261-5665252</p>
                        <p>/</p>
                        <p>261-6066990</p>
                    </div>
                    <a target='_blank'
                    rel='noopener noreferrer' 
                    href={'mailto:contacto@mendocomp.com.ar'}>
                        contacto@mendocomp.com.ar
                    </a>
                </div>
            </div>
            }
        </>
    )
}