
import useClients from "../../hooks/useClients";
import ClientsTable from "../ClientsTable/ClientsTable";
import Loader from "../Loader/Loader";

export default function ClientsContainer ({ active }) {
 
    //HOOKS
    const { clients, getClients } = useClients()

    return(
        <>
        {clients.length > 0 ?
            <ClientsTable clients={clients} getClients={getClients} />
            :
            <Loader/>
        }
        </>
    )
}