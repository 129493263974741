import { createContext, useState, useEffect } from 'react'

import { getDoc, doc, getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import app from '../fb'

//CREATES CONTEXT
const AppContext = createContext()
const {Provider} = AppContext

//Initialize Cloud Firestore and get reference to the service
const db = getFirestore(app)

//Initalize Firebase
const auth = getAuth(app)


const AppProvider = ({ children }) => {
    
    //STATES
    const [ globalUser, setGlobalUser ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)

    //FUNCTIONS
    const capitalizeWords = ( string ) =>{
        return(
            string
            .toLowerCase()
                .split(' ')
                .map((word)=>word.charAt(0).toUpperCase()+word.slice(1))
                .join(' ')
        )
    }

    //This function gets user info from DB and stores is in a State
    const updateContextUser = (email) => {

        //Creates reference to doc, looking for a doc containing user's email 
        const docRef = doc(db, 'users', email)
        getDoc(docRef)
        .then((snapshot)=>{

            //If this document exists
            if(snapshot.exists()){

                //Updates state with user information
                setGlobalUser(snapshot.data())
                // console.log(snapshot.data())
            } else {

            }
            
        })
        .catch((err)=>{console.error(err.message)})
    }

    useEffect(()=>{
        //Calls FIrebase function when app mounts
        onAuthStateChanged(auth, (fbUser)=>{
            if(fbUser){
                //If a user is found, calls fn to update context User
                updateContextUser(fbUser.email)
                setIsLoading(false)

            }else{
                //If not, sets context User to null (SignOut)
                setGlobalUser(null)
            }
        })
    },[])
    
    return(
        <Provider
        value={{
            globalUser,
            capitalizeWords,
            isLoading, 
            setIsLoading
        }}>
            {children}
        </Provider>
    )
}

export { AppContext, AppProvider }