import { useCallback, useEffect, useState } from "react";
import { collection, getDocs, getFirestore} from "firebase/firestore"; 

import app from "../fb";

function useClients() {

    //STATES
    const [clients, setClients] = useState([])

    //FIREBASE
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);
    
    //FUNCTIONS
    const getClients = useCallback(async () => {
        let clientsArray = []
        
        const querySnapshot = await getDocs(collection(db, "users"));
        querySnapshot.forEach(doc => {
            // // doc.data() is never undefined for query doc snapshots
            clientsArray.push(doc.data())
        });

        setClients(clientsArray)
        
    },[db])

    //EFFECTS
    useEffect(() => {
        getClients()

    },[getClients])

    return {
        clients,
        getClients
    }
}

export default useClients