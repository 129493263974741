import Papa from 'papaparse'
import { useState } from 'react'
import { writeBatch, doc, getFirestore } from 'firebase/firestore'
import Swal from 'sweetalert2'

import app from '../../fb'
import Loader from '../Loader/Loader'

export default function PriceListUploader() {

    //Initialize Cloud Firestore and get reference to the service
    const db = getFirestore(app)

    const [ data, setData ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)

    const handleFile = (e) => {
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function(res) {
        
                //Stores data in state
                setData(res)
            }
        })
    }

    const handleUpload = async () => {

        try {
            //Turns loader off and clears component state
            setIsLoading(true)

            //Batch to upload bulk
            const batch = writeBatch(db)
            
            //Creates reference for new list under priceLists collection:
            const priceListRef = doc(db, 'priceLists', 'currentList')

            //Sets document and commits
            batch.set(priceListRef, data)
            await batch.commit()

            //Turns loader off and clears component state
            setIsLoading(false)
            setData(null)

            Swal.fire({
                title: 'Operación completada',
                text: 'La lista ha sido actualizada.',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
            })
        } catch (e){
            //If upload fails
            Swal.fire({
                title: 'Oh oh...',
                text: e.message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass:{
                    confirmButton: 'btn btn-primary alert-btn',
                    popup: 'alert-container'
                }
            })
        }
    }

    return(
        <>  
            {isLoading?
                <Loader/>
                :
                <>
                {!data ?
                    <div className='file-input-container'>
                        <input type="file" accept=".csv" onChange={handleFile} className=''/>
                    </div>
                :
                    <button onClick={handleUpload} className='btn light' >
                        Publicar Lista
                    </button>
                }
                </>
            }
        </>
    )
}