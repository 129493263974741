import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCSJzPX_kD_EYzOZzQEuiFeG16B_SVUfUM",
    authDomain: "mendocomp.firebaseapp.com",
    projectId: "mendocomp",
    storageBucket: "mendocomp.appspot.com",
    messagingSenderId: "338832854449",
    appId: "1:338832854449:web:e3a89c96f80ecec1804190"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  export default app