import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

export default function Footer() {

    const year = new Date().getFullYear()

    return(
        <footer className="footer-main-container flex-row">
            <p>{`Mendocomp ${year}. Todos los derechos reservados.`}</p>
            {/* <div className="social-container">
                <a 
                target='_blank'
                rel='noopener noreferrer' 
                href='mailto:contacto@mendocomp.com.ar'
                >
                <FontAwesomeIcon icon={faEnvelope}/>
            </a>
                <FontAwesomeIcon icon={faWhatsapp}/>
            </div> */}
        </footer>
    )
}